#staking, #farming {
    .pools-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 2rem;
        column-gap: 1rem;
        padding: 2rem 0;

        @include breakpoint(tablet) {
            grid-template-columns: 1fr 1fr;
        }

        @include breakpoint(phablet) {
            grid-template-columns: 1fr;
        }
    }
}